<template>
  <div class="layout-border">
    <div class="mainCont">
      <Star :totalStarNumber="starNum" :currentIndex="steps" />
      <div class="background">
        <img class="bg-image" :src="bgImage" />
        <div class="backgroundImg_n_DragArea">
          <div class="drop-area" :class="{ startDrop: isStartDrop }">
            <img
              v-for="(item, index) in optionsList[steps]"
              :key="index + 'rain'"
              class="drop-item"
              :style="{
                bottom: Math.random() * 40 + '%',
                left: Math.random() * 10 + 20 * (index + 1) + '%',
              }"
              :src="item.image"
              @click="foundAnswer(item, index)"
            />
          </div>
        </div>

        <div class="answer-area" v-if="!isLastStep">
          <div class="answer-box">
            <div
              v-for="(item, index) in answerList[steps]"
              :key="index"
              class="answer-item"
            >
              <span v-if="item.isChooseCorrect" class="pinyin font-pinyin-medium">{{
                item.pinying
              }}</span>
              <span
                v-if="item.isChooseCorrect"
                class="font-hanzi-medium"
                >{{ item.hanzi }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :numBers="steps"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
    ></PageButton>
  </div>
</template>
<script>
import Star from "../Star";
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  components: {
    PageButton,
    Star,
  },
  props: {
    starNum: {
      type: Number,
      default: 0,
    },
    bgImage: {
      type: String,
      default: "",
    },
    optionsList: {
      type: Array,
      require: true,
      default: () => [],
    },
    answerList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      steps: 0,
      isStartDrop: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      isLastStep: false,
      rainSocketInfo: {},
    };
  },
  mounted() {
    this.isStartDrop = true;
    this.$bus.$on("clickRainDrop", ({ item, index }) => {
      this.foundAnswer(item, index, true);
    });
  },
  beforeDestroy(){
    this.$bus.$off("clickRainDrop")
  },
  watch: {
    rainSocketInfo: {
      handler(value) {
        console.log(value);
        const data = {
          clickType: 20038,
          data: { value },
          text: "RainDropGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    updateNumbers(value) {
      this.numBers = value;
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    foundAnswer(item, index, isFromSocket = false) {
      if (!isFromSocket) {
        this.rainSocketInfo = {
          item,
          index,
          value: Math.random(),
        };
      }
      console.log(item);
      if (item.isAnswer) {
        playCorrectSound(true, false);
        this.$emit("changeAnswerStatus", item, index);
        this.isStartDrop = false;
        setTimeout(() => {
          this.isStartDrop = true;
          this.steps++;
          if (this.steps === this.answerList.length) {
            this.steps = this.answerList.length;
            this.isLastStep = true;
            playCorrectSound();
            startConfetti();
            this.isStartDrop = false;
          }
        }, 1000);
      } else {
        playCorrectSound(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
}

.drop-area {
  height: 100%;
  width: 100%;
  top: -100%;
  position: absolute;
}

@keyframes dropAnim {
  0% {
    top: -100%;
  }

  100% {
    top: 0;
  }
}
.startDrop {
  animation: dropAnim 10s linear infinite;
  animation-direction: normal;
  animation-iteration-count: 100;
}

.drop-item {
  position: absolute;
  width: 13vh;
  cursor: pointer;
}
.drop-item:active {
  transform: scale(0.95);
}
.drop-item:hover {
  transform: scale(1.1);
}

.answer-area {
  bottom: 0;
  width: 50%;
  height: 150px;
  position: absolute;
  display: flex;
  justify-content: center;
  left: 25%;
  .redBackground {
    width: 100%;
    height: 50%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    //@debug  background-color: #d87065;
    position: absolute;
    bottom: 0;
  }
  .answer-box {
    display: flex;
    bottom: 0;
    height: -moz-fit-content;
    justify-content: space-between;
    height: 90%;
    width: 78%;
    position: absolute;
    bottom: 10%;
    border-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #f4d737;
    justify-content: space-evenly;
    align-items: center;
  }
}
.answer-item {
  max-width: 27%;
  min-width: 80px;
  min-height: 100px;
  z-index: 1;
  border-radius: 15px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;

  span {
    color: #224e96;
    font-size: 25px;
  }
  span:nth-child(2) {
    color: #224e96;
    font-size: 30px;
  }
  img {
    width: 60%;
  }
}

.mainCont {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 48px;
  position: relative;
  overflow: hidden;
}
.blueBg {
  background: #abeaff !important;
}
.background {
  text-align: -webkit-center;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #224e96;
  border-radius: 48px;
  margin: auto;
  overflow: hidden;
  .bg-image {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.backgroundImg_n_DragArea {
  position: relative;
  width: -moz-fit-content;
  width: 100%;
  height: calc(100%);
  justify-content: center;
  z-index: 0;
  border-radius: 10%;
  display: flex;
}
</style>
