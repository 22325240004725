<template>
  <div class="frog-jump-game-container layout-border" :class="'lesson-'+lessonNum">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :numBers="currentIndex"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
    ></PageButton>
    <div class="game-content">
      <Star
        :totalStarNumber="durationArr.length - 1"
        :currentIndex="clickIndex"
      />
      <div class="bg-img" ref="moveGameBox">
        <img :src="bgImg" alt="" />
        <div
          class="click-area"
          :style="{ width: scrollWidth + 'px', height: '100%' }"
        >
          <!-- <div :class="'jumpLocation-' + lessonNum"> -->
          <div
            class="item-box leaf-step"
            v-for="(item, index) in durationArr"
            :key="index"
            :class="'leaf-steps-' + index"
            @click="handleClickLeaf('socket')"
          ></div>
          <div class="safeImg"><img :src="safeImg" alt=""></div>
          <!-- </div> -->
        </div>
        <div
          ref="student"
          class="move-wrapper"
          :style="{ width: scrollWidth + 'px', height: '100%' }"
        >
          <div class="frog-img" :class="'step-' + step">
            <img :src="imgInfo.frogImg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Star from "../Star";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools";

export default {
  props: {
    bgImg: {
      type: String,
      require: true,
    },
    durationArr: {
      type: Array,
      require: true,
    },
    imgInfo: {
      type: Object,
      require: true,
    },
    lessonNum: {
      typeof: Number,
      require: true,
    },
  },
  components: {
    Star,
    PageButton,
  },
  data() {
    return {
      step: 0,
      scrollDom: null,
      scrollWidth: null,
      distance: 0,

      socketInfo: {},
      clickIndex: 0,
      currentIndex: 0,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 0,
            endNumberRange: 2,
            numBerValueAfterClick: null,
          },
          {
            type: "next",
            startNumberRange: 3,
            endNumberRange: 3,
            numBerValueAfterClick: 3,
          },
        ],
      },
      safeImg: require('@/assets/img/16-GAMES/G12-map/safe.svg'),
    };
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.scrollDom = this.$refs.moveGameBox;
        this.scrollWidth = this.scrollDom.scrollWidth;
        // console.log(this.scrollWidth);
      });
    }, 1500);

    this.$bus.$on("clickPinyinLeaf", () => {
      this.handleClickLeaf("socket", true);
    });
  },
  watch: {
    socketInfo: {
      handler(value) {
        const data = {
          clickType: 20031,
          data: { value },
          text: "FrogJumpGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClickLeaf(socket, ifFromSocket = false) {
      if (!ifFromSocket) {
        this.socketInfo = {
          index: Math.random(),
        };
      }
      if (this.step >= this.durationArr.length - 1) {
        return;
      }
      this.step++;
      this.clickIndex++;
      console.log(this.step);
      if (this.step > this.durationArr.length - 1) {
        return;
      }
      if (this.step > 1) {
        this.ControlScroll(this.step);
      }
      playCorrectSound(true, false);
    },
    ControlScroll(step) {
      this.scrollMove(this.durationArr[step - 1]);
    },
    scrollMove(num) {
      console.log(num, "++++++++++");
      const speed = 2;
      let timer = setInterval(() => {
        this.distance += speed;
        this.scrollDom.scrollLeft = this.distance;
        if (this.distance >= this.scrollWidth * num) {
          clearInterval(timer);
          if (this.step >= this.durationArr.length - 1) {
            startConfetti();
            playCorrectSound();
            this.safeImg = require('@/assets/img/16-GAMES/G12-map/safe-open.svg'),
            this.isLastStep = true;
            return;
          }
        }
      }, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.frog-jump-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .bg-img {
      //   width: 100%;
      height: 100%;
      overflow-x: scroll;
      border-radius: 58px;
      position: relative;
      img {
        height: 100%;
        // width: 100%;
        // object-fit: contain;
      }
      .click-area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        .item-box {
          // background: #000;
          width: 80px;
          height: 50px;
          position: absolute;
          bottom: 20%;
          left: 20%;
          cursor: pointer;
          border-radius: 20px;
          // opacity: 0.5;
        }
        // 游戏步骤 start
        // .jumpLocation-8 {

        .leaf-steps-0 {
          width: 111px;
          height: 64px;
          bottom: 22%;
          left: 4.5%;
        }
        .leaf-steps-1 {
          width: 102px;
          height: 56px;
          bottom: 47%;
          left: 7%;
        }
        .leaf-steps-2 {
          width: 102px;
          height: 56px;
          bottom: 70%;
          left: 9.5%;
        }
        .leaf-steps-3 {
          width: 163px;
          height: 74px;
          bottom: 69%;
          left: 12%;
        }
        .leaf-steps-4 {
          width: 163px;
          height: 74px;
          bottom: 53%;
          left: 15%;
        }
        .leaf-steps-5 {
          width: 163px;
          height: 74px;
          bottom: 27%;
          left: 19%;
        }
        .leaf-steps-6 {
          width: 100px;
          height: 74px;
          bottom: 14%;
          left: 22%;
        }

        .leaf-steps-7 {
          width: 314px;
          height: 78px;
          bottom: 25%;
          left: 24.5%;
        }
        .leaf-steps-8 {
          width: 314px;
          height: 78px;
          bottom: 49%;
          left: 30.5%;
        }
        .leaf-steps-9 {
          width: 314px;
          height: 70px;
          bottom: 71%;
          left: 36%;
        }
        .leaf-steps-10 {
          width: 314px;
          height: 70px;
          bottom: 52%;
          left: 41.3%;
        }
        .leaf-steps-11 {
          width: 314px;
          height: 70px;
          bottom: 20%;
          left: 46%;
        }
        .leaf-steps-12 {
          width: 314px;
          height: 70px;
          bottom: 14%;
          left: 52.5%;
        }
        .leaf-steps-13 {
          width: 312px;
          height: 81px;
          bottom: 24%;
          left: 60%;
        }
        .leaf-steps-14 {
          width: 180px;
          height: 74px;
          bottom: 51%;
          left: 65.5%;
        }
        .leaf-steps-15 {
          width: 280px;
          height: 78px;
          bottom: 69%;
          left: 68.8%;
        }
        .leaf-steps-16 {
          width: 177px;
          height: 78px;
          bottom: 49%;
          left: 74.8%;
        }
        .leaf-steps-17 {
          width: 280px;
          height: 78px;
          bottom: 31%;
          left: 79.5%;
        }
        .leaf-steps-18 {
          width: 151px;
          height: 84px;
          bottom: 11%;
          left: 87%;
        }
        .leaf-steps-19 {
          width: 280px;
          height: 84px;
          bottom: 27%;
          left: 93%;
        }
        .leaf-steps-20 {
          width: 180px;
          height: 84px;
          bottom: 72%;
          left: 96%;
        }

        .safeImg {
          position: absolute;
          width: 180px;
          height: 84px;
          bottom: 72%;
          left: 96%;
          img {
            border-radius: 0;
          }
        }
        // }
      }
      .move-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        .frog-img {
          position: absolute;
          bottom: 8%;
          left: 2%;
          height: 10%;
          display: inline-block;
          img {
            width: 100%;
          }
        }
        .step-1 {
          bottom: 26%;
          left: 4.7%;
        }
        .step-2 {
          bottom: 49%;
          left: 7.3%;
        }
        .step-3 {
          bottom: 72%;
          left: 9.7%;
        }
        .step-4 {
          bottom: 74%;
          left: 12.7%;
        }
        .step-5 {
          bottom: 55%;
          left: 15.5%;
        }
        .step-6 {
          bottom: 30%;
          left: 19.8%;
        }
        .step-7 {
          bottom: 16%;
          left: 22.2%;
        }
        .step-8 {
          bottom: 31%;
          left: 26.2%;
        }
        .step-9 {
          bottom: 55%;
          left: 32.1%;
        }
        .step-10 {
          bottom: 76%;
          left: 37.8%;
        }
        .step-11 {
          bottom: 57%;
          left: 43.2%;
        }
        .step-12 {
          bottom: 26%;
          left: 47.96%;
        }
        .step-13 {
          bottom: 18%;
          left: 54.6%;
        }
        .step-14 {
          bottom: 30%;
          left: 61.7%;
        }
        .step-15 {
          bottom: 54%;
          left: 66.3%;
        }
        .step-16 {
          bottom: 74%;
          left: 70.5%;
        }
        .step-17 {
          bottom: 52%;
          left: 75.4%;
        }
        .step-18 {
          bottom: 36%;
          left: 81.2%;
        }
        .step-19 {
          bottom: 14%;
          left: 87.6%;
        }
        .step-20 {
          bottom: 31%;
          left: 94.9%;
        }
        .step-21 {
          bottom: 31%;
          left: 94.9%;
        }
      }
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }

  &.lesson-48 {
    .click-area {
      left: 20px !important;
      top: -30px !important;
      .item-box {
        // background: red;
        // opacity: 0.5;
      }

      .leaf-steps-7, .leaf-steps-8, .leaf-steps-9, .leaf-steps-10, .leaf-steps-11, .leaf-steps-12, .leaf-steps-13, .leaf-steps-14, .leaf-steps-15, .leaf-steps-16, .leaf-steps-17, .leaf-steps-18, .leaf-steps-19,  {
        width: 414px !important;
        height: 100px !important;
        
      }
    }
  }
}
</style>