<template>
  <div class="write-page-heng-container">
    <div class="write-stroke-page-container layout-border">
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        @clickNext="nextPage"
        @updateNumbers="updateNumbers($event)"
        @click.native="startWritingStroke"
      ></PageButton>
      <div class="stroke-page-box">
        <div class="write-stroke-content">
          <transition name="el-fade-in">
            <div class="write-stroke-area">
              <!-- left 左边 -->
              <div class="write-stroke-content-left">
                <div class="write-stroke-left-content">
                  <div class="left-content-border"></div>
                  <div class="left-content">
                    <div class="stroke-pinyin">
                      <span class="pinyin font-pinyin-large">{{
                        currentStrokeInfo.pinyin
                      }}</span>
                    </div>

                    <!-- default 默认左边背景 -->
                    <div
                      class="stroke-area"
                      @mousedown="startDrawing()"
                      @mouseup="endDrawing($event)"
                    >
                      <div class="hanzi-img">
                        <KidsWhiteBoard ref="drawArea" v-if="isShowEye" />
                        <!-- drawing -->

                        <div class="hengIcon">
                          <img
                            v-if="!showStroke"
                            class="stroke-square-img"
                            :src="currentStrokeInfo.strokeImg"
                            alt=""
                          />
                          <img
                            v-else
                            class="stroke-square-img"
                            :src="currentStrokeInfo.redStrokeImg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- right 右边 -->
              <div class="write-stroke-content-right">
                <div class="right-img-content">
                  <div
                    class="img-item"
                    v-for="(item, index) in showImgList"
                    :key="index + 'show'"
                  >
                    <img
                      :src="item.showRedImg"
                      alt=""
                      v-if="currentIndex > index"
                    />
                    <img :src="item.showImg" alt="" v-else />
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import KidsWhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue"; // 白板组件
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  props: {
    strokeImgList: {
      type: Array,
      require: true,
    },
    showImgList: {
      type: Array,
      require: true,
    },
    pinyinHelper: {
      type: Array,
      require: true,
    },
  },
  created() {
    this.currentStrokeInfo = this.strokeImgList[this.currentIndex];
  },
  data() {
    return {
      showCanvasSocketInfo: {},
      nextStrokeSocketInfo: {},
      currentStrokeInfo: {},
      currentIndex: 0,
      characterCheckpoints: [
        {
          id: 1,
          hitCheckpoint: false,
          finishedCheckpoint: false,
        },
        {
          id: 2,
          hitCheckpoint: false,
          finishedCheckpoint: false,
        },
        {
          id: 3,
          hitCheckpoint: false,
          finishedCheckpoint: false,
        },
      ],
      isShowEye: false,
      isDrawHover: false,
      isShowDrawButton: true,
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      eyeIsHover: false,
      isFinishWriting: false,
      isShowHand: false,
      drawing: false,
      showStroke: false,
      startWritingStrokeValue: 0,
      finishedStrokeValue: 0,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "draw",
            startNumberRange: 1,
            endNumberRange: 3,
          },
        ],
      },
    };
  },
  components: {
    KidsWhiteBoard,
    PageButton,
  },
  mounted() {
    this.$bus.$on("startReviewStroke", () => {
      this.startWritingStroke(true);
    });
    this.$bus.$on("drawSingleStrokeReview", () => {
      this.finishedStroke(true);
    });
  },
  beforeDestroy() {
    this.$bus.$emit("startStrokePage", false);
    this.$bus.$off("startReviewStroke");
    this.$bus.$off("drawSingleStrokeReview");
  },
  watch: {
    isShowEye(value) {
      this.$bus.$emit("startStrokePage", value);
    },
    startWritingStrokeValue: {
      handler() {
        const data = {
          clickType: 2002401,
          data: { value: this.socketInfo },
          text: "WriteStrokeReview点击startsocket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    finishedStrokeValue: {
      handler() {
        const data = {
          clickType: 2002402,
          data: { value: this.socketInfo },
          text: "WriteStrokeReview点击startsocket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    startDrawing() {},
    endDrawing() {
      this.finishedStroke();
    },

    finishedStroke(type) {
      playCorrectSound();
      startConfetti();
      this.showStroke = true;
      this.handleSteps(type);
    },

    // 开始写笔画
    startWritingStroke(isFromSocket = false) {
      if (!isFromSocket) {
        this.startWritingStrokeValue++;
        this.showCanvasSocketInfo = {
          index: Math.random(),
        };
      }
      this.isShowEye = true;
    },
    // 下一页
    nextPage() {
      this.$bus.$emit("startStrokePage", false);
      this.$bus.$emit("nextButton", true);
    },
    // 步数
    handleSteps(isFromSocket = false) {
      if (!isFromSocket) {
        this.finishedStrokeValue++;
        this.nextStrokeSocketInfo = {
          index: Math.random(),
        };
      }

      if (this.currentIndex >= this.pinyinHelper.length) {
        this.isLastStep = true;
        return;
      }
      this.currentIndex++;
      setTimeout(() => {
        this.$bus.$emit("controlCanvas", "clear");
        this.currentStrokeInfo = this.strokeImgList[this.currentIndex];
        console.log(this.currentIndex);
        if (this.currentIndex === this.pinyinHelper.length) {
          this.isLastStep = true;
          this.currentStrokeInfo =
            this.strokeImgList[this.pinyinHelper.length - 1];
        }
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.write-stroke-page-container {
  .stroke-page-box {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 58px;
    position: relative;
    .write-stroke-content {
      background: #f7f4e0;
      // padding: 10px 10px 30px 10px;
      background-size: cover;
      width: 100%;
      height: 100%;
      border-radius: 58px;
      // display: flex;
      position: relative;
      .write-stroke-area {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        .write-stroke-content-left {
          // position: absolute;
          position: relative;
          display: flex;
          width: 50%;
          height: 100%;
          justify-content: flex-end;
          align-items: center;
          .write-stroke-left-content {
            position: relative;
            width: 80%;
            height: 70%;
            .left-content-border {
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              position: absolute;
              right: 0;
              bottom: 0;
              background: #224e96;
              border-radius: 30px;
            }
            .left-content {
              color: #000;
              text-align: center;
              position: absolute;
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              background: #fff;
              border-radius: 30px;
              top: 0;
              left: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              cursor: default;
              .stroke-pinyin {
                height: 15%;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                .pinyin {
                  color: #cd4c3f;
                  // display: inline-block;
                }
              }

              .stroke-area {
                margin-top: 10%;
                width: 80%;
                height: 75%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                .hanzi-img {
                  position: relative;
                  width: 100%;
                  .hengIcon {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    img {
                      width: 100%;
                      position: absolute;
                      top: 0;
                      left: 0;
                      bottom: 0;
                      right: 0;
                      margin: auto;
                      -khtml-user-select: none;
                      -o-user-select: none;
                      -moz-user-select: none;
                      -webkit-user-select: none;
                      user-select: none;
                      -webkit-user-drag: none;
                    }
                  }
                }
                .stroke-square-img {
                  cursor: pointer;
                  -khtml-user-select: none;
                  -o-user-select: none;
                  -moz-user-select: none;
                  -webkit-user-select: none;
                  user-select: none;
                  -webkit-user-drag: none;
                }
                div {
                  width: 100%;
                  height: 0;
                  padding-bottom: 100%;
                  position: absolute;
                }
                img {
                  width: 100%;
                  -khtml-user-select: none;
                  -o-user-select: none;
                  -moz-user-select: none;
                  -webkit-user-select: none;
                  user-select: none;
                  -webkit-user-drag: none;
                }
              }
            }
          }
        }
        .write-stroke-content-right {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .right-img-content {
            width: 80%;
            height: 60%;
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-around;
            align-items: center;
            .img-item {
              width: 40%;
              margin-right: 5%;
              //   margin-bottom: 5%;
              img {
                width: 70%;
              }
            }
          }
        }
      }
    }
  }
}
.confettiCanvas {
  z-index: 900;
  position: absolute;
}
</style>
<style lang="scss" scoped>
.write-page-heng-container {
  width: 100%;
  height: 100%;
}
</style>
